const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.debmarine.forwoodsafety.com',
    APP_CLIENT_ID: '6krndg7j9634gjgec1k6go9mm1',
    USER_TOKEN_URL: 'https://0szmyay8ie.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://c9gy40jvj6.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.9.1',
    HOSTNAME: 'https://admin.debmarine.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.debmarine.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.debmarine.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.debmarine.forwoodsafety.com',
    WEBSOCKET: 'wss://tlqypz0zw1.execute-api.us-west-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;